import { useState } from "react";
import { IVisaBlock } from "../../interfaces/VisaBlockInterface";
import { motion } from "framer-motion";

export const VisaBlock = ({ number, title, text, bgImg }: IVisaBlock) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <motion.div
      className="w-full h-[17rem] md:h-[17.5rem] md:w-[17.5rem] mediumScreen:h-[22.5rem] mediumScreen:w-[22.5rem] p-8"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onTouchStart={handleMouseEnter}
      onTouchEnd={handleMouseLeave}
    >
      <div className="text-end">
        <p className="bg-[#0d2951] inline-block py-1 px-3 rounded-full">
          {number}
        </p>
      </div>
      <div className="h-[85%] flex flex-col justify-end">
        <p className="mb-4">{title}</p>
        <hr />
        <p className={`mt-4 description ${isHovered ? "mt-10 visible" : ""}`}>
          {text}
        </p>
      </div>
    </motion.div>
  );
};
