import { useTranslation } from "react-i18next";
import mainPhoto from "../../images/MainImages/mainPhoto.png";
import MainLogo from "../../images/logo.svg";
import { motion } from "framer-motion";
import { tabsAnimation, titleAnimation } from "../../animations/animations";
import { Link } from "react-scroll";

export const TopMain = () => {
  const { t } = useTranslation();

  const handlePdf = () => {
    const pdfUrl = `${process.env.PUBLIC_URL}/licenses.pdf`;
    window.open(pdfUrl, "_blank");
  };

  return (
    <div
      className="h-full"
      style={{
        backgroundImage: `url(${mainPhoto})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "scroll",
        backgroundSize: "cover",
      }}
    >
      <div className="container__standart relative">
        <img
          className="hidden md:block brightness-50 absolute right-[10px] bottom-[10%]"
          src={MainLogo}
          alt="MainLogo"
        />
        <div className="pt-[9rem] mainPageLG:pt-[12.5rem] text-white">
          <motion.h1
            initial="hidden"
            animate="visible"
            variants={titleAnimation}
          >
            {t("topMain.title")}
          </motion.h1>
          <div className="flex flex-col py-4">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={tabsAnimation}
              className="pb-[60px] h-[428px]"
            >
              <p className="mt-10 mb-3 w-full text-[1rem] xsm:w-[20rem] xsm:text-[1.263rem] mainPageLG:text-[1.563rem]">
                {t("topMain.leaveRequest")}
              </p>
              <span className="text-[#afafaf] text-[1.563rem]">
                {t("topMain.workOnline")}
              </span>
              <Link
                className="top__button mt-5 cursor-pointer"
                to="/reques"
                smooth={true}
                duration={700}
              >
                {t("topMain.buttonVacan")}
              </Link>
              <button onClick={handlePdf} className="top__button mt-3">
                {t("topMain.buttonLicen")}
              </button>
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
