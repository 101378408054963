import Coca from "../../images/SponsorLogo/coca.svg";
import Milka from "../../images/SponsorLogo/milka.svg";
import Ikea from "../../images/SponsorLogo/ikea.svg";
import Nissan from "../../images/SponsorLogo/nissan.svg";
import Lego from "../../images/SponsorLogo/lego.svg";
import Porch from "../../images/SponsorLogo/porch.svg";
import Lacoste from "../../images/SponsorLogo/lacoste.svg";
import Amazon from "../../images/SponsorLogo/amazon.svg";
import { useTranslation } from "react-i18next";
import AboutUsBg from "../../images/MainImages/aboutBackground.jpg";
import OurServices from "../../images/MainImages/ourServices.jpg";
import { MWhyWeList } from "../../components/WhyWeList/WhyWeList";
import { accordionType } from "../../types/accordionType";
import { listType } from "../../types/listType";
import { Visa } from "../../components/Visa/Visa";
import { Carousel } from "../../components/Carousel/Carousel";
import feedback1 from "../../images/Revuse/123.png";
import feedback2 from "../../images/Revuse/124.png";
import feedback3 from "../../images/Revuse/125.png";
import { ApplicationSection } from "../../components/ApplicationSection/ApplicationSection";
import { VacCarousel } from "../../components/Carousel/VacCarousel";
import { TopMain } from "../../components/TopMain/TopMain";
import { motion } from "framer-motion";
import {
  arrOfBlocksAnimation,
  tabsAnimation,
  titleAnimation,
} from "../../animations/animations";

export const MainPage = () => {
  const { t } = useTranslation();
  const accordionInfo = t("ourServices.accordion", {
    returnObjects: true,
  }) as Array<accordionType>;
  const listInfo = t("whyWe.whyWelist", {
    returnObjects: true,
  }) as Array<listType>;

  const feedbacks = [feedback1, feedback2, feedback3];

  return (
    <main>
      <section id="/">
        <TopMain />
      </section>
      <section className="py-20">
        <div className="container__standart">
          <motion.h2
            className="mb-[2.5rem]"
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={titleAnimation}
          >
            {t("ourPartners.title")}
          </motion.h2>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={tabsAnimation}
            className="text-2xl font-light text-center"
          >
            {t("ourPartners.subTitle")}
          </motion.p>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="mt-8 w-full flex flex-wrap [&>*]:w-[45%] justify-between sm:[&>*]:w-[20%] sm:[&>*]:mx-1"
          >
            <motion.img
              custom={1}
              variants={arrOfBlocksAnimation}
              src={Amazon}
              alt="Adidas"
            ></motion.img>
            <motion.img
              custom={2}
              variants={arrOfBlocksAnimation}
              src={Nissan}
              alt="Hilton"
            ></motion.img>
            <motion.img
              custom={3}
              variants={arrOfBlocksAnimation}
              src={Lacoste}
              alt="Dhl"
            ></motion.img>
            <motion.img
              custom={4}
              variants={arrOfBlocksAnimation}
              src={Ikea}
              alt="Adidas"
            ></motion.img>
            <motion.img
              custom={5}
              variants={arrOfBlocksAnimation}
              src={Lego}
              alt="Hilton"
            ></motion.img>
            <motion.img
              custom={6}
              variants={arrOfBlocksAnimation}
              src={Porch}
              alt="Volk"
            ></motion.img>
            <motion.img
              custom={7}
              variants={arrOfBlocksAnimation}
              src={Milka}
              alt="Milka"
            ></motion.img>
            <motion.img
              custom={8}
              variants={arrOfBlocksAnimation}
              src={Coca}
              alt="Coca"
            ></motion.img>
          </motion.div>
        </div>
      </section>
      <section
        id="/about-us"
        style={{
          backgroundImage: `url(${AboutUsBg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container__big py-10 text-white">
          <h2 className="text-left">{t("aboutUs.title")}</h2>
          <div className="[&>p]:text-xl [&>p]:my-7">
            <p>{t("aboutUs.p1")}</p>
            <p>{t("aboutUs.p2")}</p>
            <p>{t("aboutUs.p3")}</p>
            <p>{t("aboutUs.p4")}</p>
            <p>{t("aboutUs.p5")}</p>
            <p>{t("aboutUs.p6")}</p>
          </div>
          <div className="[&>*]:text-center [&>*]:font-bold [&>p]:text-2xl [&>span]:block [&>div]:my-8 [&>div]:border-b-2 [&>div]:pb-2 [&>div]:text-2xl">
            <div>
              <span className="text-3xl">{t("aboutUs.span1")}</span>
              <p>{t("aboutUs.dis1")}</p>
            </div>
            <div>
              <span className="text-3xl">{t("aboutUs.span2")}</span>
              <p>{t("aboutUs.dis2")}</p>
            </div>
            <div>
              <span className="text-3xl">{t("aboutUs.span3")}</span>
              <p>{t("aboutUs.dis3")}</p>
            </div>
            <div>
              <span className="text-3xl">{t("aboutUs.span4")} </span>
              <p>{t("aboutUs.dis4")}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="py-10 bg-[#dddddd]" id="/vacancies">
        <div className="container__big">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={titleAnimation}
          >
            {t("openPositions.title")}
          </motion.h2>
          <motion.p
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={tabsAnimation}
            className="w-full text-center mx-auto my-10 text-xl md:w-[37.5rem]"
          >
            {t("openPositions.subTitle")}
          </motion.p>
          <VacCarousel />
        </div>
      </section>
      <section
        id="/services"
        style={{
          backgroundImage: `url(${OurServices})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className="py-10"
      >
        <div className="container__standart">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.01, once: true }}
            variants={titleAnimation}
            className="text-white mb-10"
          >
            {t("ourServices.title")}
          </motion.h2>
          <motion.ul
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.01, once: true }}
          >
            {accordionInfo.map((oneAccordion: accordionType, index: number) => {
              return (
                <motion.div
                  custom={index}
                  variants={arrOfBlocksAnimation}
                  key={index}
                  className="flex items-center py-2"
                >
                  <span className="w-[3rem] h-[3rem] xsm:w-[3.75rem] xsm:h-[3.75rem] rounded-full bg-[#45413e] flex items-center justify-center text-xl font-medium text-white">
                    {index + 1}
                  </span>
                  <p className="ml-5 w-[calc(100%-80px)] text-white text-m xsm:text-xl">
                    {oneAccordion.paragraph}
                  </p>
                </motion.div>
              );
            })}
          </motion.ul>
        </div>
      </section>
      <section id="/employment">
        <Visa />
      </section>
      <section
        id="/why-we"
        className="py-10"
        style={{
          backgroundImage: `url(${OurServices})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="container__standart text-white">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            variants={titleAnimation}
            className="pb-10"
          >
            {t("whyWe.title")}
          </motion.h2>
          <motion.div
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.1, once: true }}
            className="flex flex-wrap"
          >
            {listInfo.map((list: listType, index: number) => {
              return (
                <MWhyWeList
                  custom={index}
                  variants={arrOfBlocksAnimation}
                  title={list.listInfo.title}
                  text={list.listInfo.paragraph}
                  key={index}
                >
                  {list.listInfo.text}
                </MWhyWeList>
              );
            })}
          </motion.div>
        </div>
      </section>
      <section>
        <div className="container__big py-10 [&>p]:text-xl [&>p]:my-7">
          <h2 className="text-left">{t("pagePersonnel.title")}</h2>
          <p>{t("pagePersonnel.p1")}</p>
          <p>{t("pagePersonnel.p2")}</p>
          <p>{t("pagePersonnel.p3")}</p>
          <p>{t("pagePersonnel.p4")}</p>
        </div>
      </section>
      <section
        id="/reviews"
        className="py-10"
        style={{
          backgroundImage: `url(${OurServices})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="px-[2rem] flex flex-col items-center justify-center text-center">
          <motion.h2
            initial="hidden"
            whileInView="visible"
            viewport={{ amount: 0.01, once: true }}
            variants={titleAnimation}
            className="text-white"
          >
            {t("feedbacks.title")}
          </motion.h2>
          <Carousel imgComments={feedbacks} />
        </div>
      </section>
      <section id="/reques">
        <ApplicationSection />
      </section>
    </main>
  );
};
