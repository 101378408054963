import { useTranslation } from "react-i18next";
import { PositionItemInterface } from "../../interfaces/PositionItemInterface";
import { PositionRow } from "../PositionRow/PositionRow";
import { Link } from "react-scroll";

export const PositionItem = ({
  img,
  title,
  salary,
  schedule,
  housing,
  employment,
  experience,
  city,
}: PositionItemInterface) => {
  const { t } = useTranslation();
  return (
    <div className="w-full xsm:w-[22.5rem] bg-white pb-3 my-3 mx-0 xsm:mx-auto relative">
      <div className="border-b-solid border-b-2 border-b-black h-[12.5rem]">
        <img className="h-full w-full" src={img} alt="" />
      </div>
      <div className="p-5 h-[24rem] xxxsm:h-[20.313rem]">
        <h4 className="mb-2">{title}</h4>
        <div className="text-sm flex flex-col [&>p]:my-1">
          {salary && (
            <PositionRow
              span={t("openPositions.salary")}
              discription={salary}
            />
          )}
          {schedule && (
            <PositionRow
              span={t("openPositions.schedule")}
              discription={schedule}
            />
          )}
          {housing && (
            <PositionRow
              span={t("openPositions.housing")}
              discription={housing}
            />
          )}
          {employment && (
            <PositionRow
              span={t("openPositions.employment")}
              discription={employment}
            />
          )}
          {experience && (
            <PositionRow
              span={t("openPositions.experience")}
              discription={experience}
            />
          )}
          {city && (
            <PositionRow span={t("openPositions.city")} discription={city} />
          )}
        </div>
      </div>
      <div className="w-full h-fit px-5 py-2 text-sm font-bold">
        <Link
          className="cursor-pointer bg-[#1f23d7] text-white p-2 flex items-center justify-center w-full"
          to="/reques"
          smooth={true}
          duration={700}
        >
          {t("openPositions.leaveApplication")}
        </Link>
      </div>
    </div>
  );
};
