import { useTranslation } from "react-i18next";
import Preparation from "../../images/VisaLogos/individual_selection_vacancies.png";
import JobSearch from "../../images/VisaLogos/checking_bans.png";
import Contract from "../../images/VisaLogos/signing_contract.png";
import VisaProcessing from "../../images/VisaLogos/receiving_documents.png";
import Labour from "../../images/VisaLogos/submitting_documents_visa.png";
import Adaptation from "../../images/VisaLogos/help_with_flights_insurance.png";
import { VisaBlock } from "../VisaBlock/VisaBlock";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";

export const Visa = () => {
  const { t } = useTranslation();

  return (
    <div className="container__big py-10">
      <motion.h2
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.01, once: true }}
        variants={titleAnimation}
      >
        {t("visa.visaTitle")}
      </motion.h2>
      <div className="flex mt-12 flex-wrap [&>div]:m-4 justify-between ssss:mx-12 text-white font-semibold">
        <VisaBlock
          number="1"
          title={t("visa.blockTitle1")}
          text={t("visa.blockText1")}
          bgImg={Preparation}
        />
        <VisaBlock
          number="2"
          title={t("visa.blockTitle2")}
          text={t("visa.blockText2")}
          bgImg={JobSearch}
        />
        <VisaBlock
          number="3"
          title={t("visa.blockTitle3")}
          text={t("visa.blockText3")}
          bgImg={Contract}
        />
        <VisaBlock
          number="4"
          title={t("visa.blockTitle4")}
          text={t("visa.blockText4")}
          bgImg={VisaProcessing}
        />
        <VisaBlock
          number="5"
          title={t("visa.blockTitle5")}
          text={t("visa.blockText5")}
          bgImg={Labour}
        />
        <VisaBlock
          number="6"
          title={t("visa.blockTitle6")}
          text={t("visa.blockText6")}
          bgImg={Adaptation}
        />
      </div>
    </div>
  );
};
