import { PositionRowInterface } from "../../interfaces/PositionRowInterface"

export const PositionRow = ({
	span,
	discription }: PositionRowInterface) => {
	return (
		<div className="flex justify-between my-1">
			<p>
				<span className="inline-block mr-2">
					{span}
				</span>
				{discription}
			</p>
		</div>
	)
}