import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { localeState } from "../../types/SliceTypes/localeState";

const initialState: localeState = {
  localStorageValue: localStorage.getItem("locale") || "ru",
};

const localeSlice = createSlice({
  name: "locale",

  initialState,

  reducers: {
    setLocale: (state, action: PayloadAction<string>) => {
      state.localStorageValue = action.payload;
      localStorage.setItem("locale", action.payload);
    },
  },
});

export const { setLocale } = localeSlice.actions;
export default localeSlice.reducer;
