import { useTranslation } from "react-i18next";
import MainLogo from "../../images/logo.svg";

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="text-white bg-black w-full px-5">
      <div className="flex flex-col tables:flex-row container__big py-[1.875rem]">
        <div className="pl-0 tables:pl-10 flex flex-col items-center tables:mr-10">
          <div className="flex flex-col items-center justify-center w-full tables:w-1/2 text-2xl font-semibold">
            <h3>WhatsApp, Telegram</h3>
            <a href="tel:+447901304697" className="text-[#8D8176]">
              +44 790 130 4697
            </a>
            <h3 className="text-center mt-4">{t("footer.phone")}</h3>
            <a href="tel:+447901304697" className="text-[#8D8176]">
              +44 790 130 4697
            </a>
            <p className="text-[20px] xxsm:text-2xl text-[#8D8176]">
              support@workconsultinggroup.me
            </p>
          </div>
          <div className="flex flex-col items-center text-center w-full text-base font-light mt-[2.813rem] mb-[1.875rem]">
            <p>
              {t("footer.administrativeBranch")} {t("footer.adress")}
            </p>
            <p className="!mt-6">Consulting Group Company</p>
            <img className="mt-5 w-[190px]" src={MainLogo} alt="" />
            <p className="!mt-6">©{t("footer.rights")}</p>
          </div>
        </div>
        <div className="w-full tables:w-1/2 mx-auto tables:mx-0">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1663.043916887726!2d27.534172875025664!3d53.880218852574224!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46dbd0226f7484d3%3A0xe555f77dedf5d061!2z0YPQu9C40YbQsCDQp9C60LDQu9C-0LLQsCAxMiwg0JzQuNC90YHQuiwg0JzQuNC90YHQutCw0Y8g0L7QsdC70LDRgdGC0YwsINCR0LXQu9Cw0YDRg9GB0Yw!5e0!3m2!1sru!2sua!4v1716724954102!5m2!1sru!2sua"
            className="w-full max-w-[560px] mx-[auto] h-[500px] mb-[1.875rem]"
            loading="lazy"
            title="Location of the Office"
          ></iframe>
        </div>
      </div>
    </footer>
  );
};
