import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import localeSlice from "./slices/localeSlice";
import logoFirstSlice from "./slices/logoFirstSlice";

export const store = configureStore({
  reducer: { locale: localeSlice, logoFirst: logoFirstSlice },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
