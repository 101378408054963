import { IWhyWe } from "../../interfaces/WhyWeInterface";
import doneIcon from "../../images/MainImages/doneSvg.svg";
import { motion } from "framer-motion";
import { ForwardedRef, forwardRef } from "react";

export const WhyWeList = forwardRef(
  ({ title, text, children }: IWhyWe, ref: ForwardedRef<HTMLDivElement>) => {
    return (
      <div
        ref={ref}
        className="w-full md:w-[47%] flex justify-between my-5 xsm:px-5"
      >
        <span className="w-[2.125rem] h-[2.125rem] bg-white flex items-center justify-center rounded-full mt-[3px]">
          <img className="w-[1.25rem] h-[1.25rem]" src={doneIcon} alt="done" />
        </span>
        <div className="w-[calc(100%-3.125rem)]">
          <h3>{title}</h3>
          <p className="text-m">{text}</p>
        </div>
      </div>
    );
  }
);

export const MWhyWeList = motion(WhyWeList);
