import "./App.css";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { ToTopBtn } from "../components/ToTopBtn/ToTopBtn";
import { MainPage } from "./Main/MainPage";
import { HeaderLogo } from "../components/HeaderLogo/HeaderLogo";
import { siteAnimation } from "../animations/animations";
import { motion } from "framer-motion";
import { useAppSelector } from "../store/hooks";

function App() {
  const isFirstLoad = useAppSelector((state) => state.logoFirst.isFirstLoad);

  return (
    <>
      {isFirstLoad ? (
        <HeaderLogo />
      ) : (
        <motion.div className="bg-white" {...siteAnimation}>
          <Header />
          <MainPage />
          <ToTopBtn />
          <Footer />
        </motion.div>
      )}
    </>
  );
}

export default App;
