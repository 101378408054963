import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useAppDispatch } from "../../store/hooks";
import Logo from "../../images/logo.svg";
import { animationLogo } from "../../animations/animations";
import { setFirstLoad } from "../../store/slices/logoFirstSlice";

export const HeaderLogo = () => {
  const dispatch = useAppDispatch();

  const handleAnimationComplete = () => {
    dispatch(setFirstLoad());
  };

  useEffect(() => {
    const restoreScroll = () => {
      document.body.style.overflow = "visible";
    };

    document.body.style.overflow = "hidden";

    return () => {
      restoreScroll();
    };
  }, []);

  return (
    <motion.div
      {...animationLogo}
      onAnimationComplete={handleAnimationComplete}
      className="flex items-center justify-center h-screen"
    >
      <img src={Logo} alt="Logo" className="w-[22rem] h-[22rem]" />
    </motion.div>
  );
};
