import { animateScroll as scroll } from "react-scroll";
import { useEffect, useState } from "react";

export const ToTopBtn = () => {
  const [ourScrollY, setOurScrollY] = useState(0);

  const handleScroll = () => {
    setOurScrollY(window.scrollY);
  };

  const goToTop = () => {
    scroll.scrollToTop();
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <button
      className={`fixed w-12 h-12 bg-[#1f23d7] z-30 cursor-pointer bottom-[30px] right-[20px] xsm:bottom-[60px] xsm:right-[40px] rounded-full opacity-30 hover:opacity-100 transition-all flex justify-center items-center ${
        ourScrollY > 300 ? "visible" : "hidden"
      }`}
      onClick={goToTop}
    >
      <div className="w-0 h-0 border-l-[5px] border-l-transparent border-r-[5px] border-r-transparent border-b-[5px] border-b-[#fff]"></div>
    </button>
  );
};
