import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import RuTranslation from "./locales/RU.json";
import EnTranslation from "./locales/EN.json";

const local = localStorage.getItem("locale") || "ru";

i18n.use(initReactI18next).init({
  resources: {
    ru: { translation: RuTranslation },
    en: { translation: EnTranslation },
  },
  lng: local,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
