import { useTranslation } from "react-i18next";
import { menuType } from "../../types/menuTypes";
import i18n from "../../i18n";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setLocale } from "../../store/slices/localeSlice";
import { Link } from "react-scroll";
import { useEffect, useState } from "react";
import MainLogo from "../../images/logo.svg"

export const Header = () => {
  const lang = useAppSelector((state) => state.locale);
  const { t } = useTranslation();
  const titles = t("titles", { returnObjects: true }) as Array<menuType>;
  const dispatch = useAppDispatch();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [activeHeader, setActiveHeader] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    };
  }, []);

  const handleClickHeader = () => {
    setActiveHeader(prevState => !prevState)
  }


  const createMenu = (titles: Array<menuType>) => {

    return (
      <>
        {titles.map((title: menuType) => {
          return (
            <li
              key={title.route}
              className="h-full">
              <Link
                className={`${windowWidth < 1320 ?
                  'text-[18px] xxsm:text-[24px] ssss:text-[32px] cursor-pointer ease-in-out duration-300 hover:translate-x-3'
                  :
                  'text-[14px] cursor-pointer ease-in-out duration-300 hover:scale-[1.2]'} `}
                to={title.route}
                smooth={true}
                duration={700}
                onClick={handleClickHeader}
              >
                {title.title}
              </Link>
            </li>
          );
        })}
      </>
    );
  };

  const changeLanguage = (lng: string) => {
    dispatch(setLocale(lng));
    i18n.changeLanguage(lng);
  };

  return (
    <header className="w-full z-10 fixed top-0 left-0">
      {windowWidth < 1320 ?

        <div className="h-[60px] bg-[#0000009e] text-white font-bold relative w-full">
          {activeHeader &&
            <nav className={`nav__animation h-[100vh] w-full bg-black absolute left-0 top-0 z-20`}>
              <img className="ml-[10px] h-[60px]" src={MainLogo} alt="" />
              <ul className="flex flex-col w-full [&>li]:mx-5 [&>*]:text-4xl [&>li]:my-2 [&>li]:flex [&>li]:items-center absolute top-[70px] left-0">
                {createMenu(titles)}
              </ul>
            </nav>
          }

          <div className="h-full w-fit">
            <button
              onClick={handleClickHeader}
              className="h-full w-10 flex flex-col justify-center items-center absolute top-0 right-[80px] xxxsm:right-[105px] z-30">
              <div className={`${activeHeader ?
                'burgerAnimation' : ''}`}>

                <span className="w-6 h-[3px] bg-white block mb-[5px]"></span>
                <span className="w-6 h-[3px] bg-white block"></span>
              </div>
            </button>
            <img className="ml-[10px] h-[60px]" src={MainLogo} alt="" />
          </div>

          <div className="flex">
            {lang.localStorageValue === "en" ? (
              <button
                onClick={() => {
                  changeLanguage("ru");
                }}
                className="font-medium text-sm w-[4.375rem] absolute top-2 right-2 xxxsm:right-5  hover:scale-[1.2] ease-in-out duration-300 z-30"
              >
                Сменить язык
              </button>
            ) : (
              <button
                onClick={() => {
                  changeLanguage("en");
                }}
                  className="font-medium text-[12px] xxxsm:text-sm w-[4.375rem] absolute top-3 xxxsm:top-2 right-2 xxxsm:right-5  hover:scale-[1.2] ease-in-out duration-300 z-30 "
              >
                Change Language
              </button>
            )}
          </div>
        </div>
        :
        <div className="h-[3.125rem] bg-[#0000009e] text-white font-bold relative w-full">
          <nav className="h-full">
            <ul className="flex items-center h-full w-fit xxl:mx-auto [&>li]:mx-4 xxl:[&>li]:mx-5 [&>li]:flex [&>li]:items-center [&>li]:justify-center ">
              {createMenu(titles)}
            </ul>
          </nav>
          <div className="flex">
            {lang.localStorageValue === "en" ? (
              <button
                onClick={() => {
                  changeLanguage("ru");
                }}
                className="font-medium text-[12px] xxxsm:text-sm w-[4.375rem] absolute top-1 right-5 hover:scale-[1.2] ease-in-out duration-300 z-30"
              >
                Сменить язык
              </button>
            ) : (
              <button
                onClick={() => {
                  changeLanguage("en");
                }}
                className="font-medium text-sm w-[4.375rem] absolute top-1 right-5 hover:scale-[1.2] ease-in-out duration-300 z-30"
              >
                Change Language
              </button>
            )}
          </div>
        </div>
      }

    </header>
  );
};
