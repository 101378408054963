import nextArrow from "../../images/MainImages/button-next.svg";
import prevArrow from "../../images/MainImages/button_previous.svg";
import nextArrowMob from "../../images/MainImages/button-next-mob.svg";
import prevArrowMob from "../../images/MainImages/button-prev-mob.svg";

interface ArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

export const NextArrow = (props: ArrowProps) => {
  const { style, onClick } = props;
  return (
    <div
      className={`absolute top-[50%] cursor-pointer -translate-y-1/2 bg-white rounded-full w-auto h-auto max-w-[3.125rem] max-h-[3.125rem] p-0 right-0 ssss:right-[10px] z-[2] opacity-[0.2] xsm:opacity-[1]`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={nextArrow} alt="next" />
    </div>
  );
};

export const PrevArrow = (props: ArrowProps) => {
  const { style, onClick } = props;
  return (
    <div
      className={`absolute top-[50%] cursor-pointer -translate-y-1/2 bg-white rounded-full w-auto h-auto max-w-[3.125rem] max-h-[3.125rem] p-0 left-0 ssss:left-[10px] z-[2] opacity-[0.2] xsm:opacity-[1]`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={prevArrow} alt="prev" />
    </div>
  );
};

export const NextArrowMob = (props: ArrowProps) => {
  const { style, onClick } = props;
  return (
    <div
      className={`absolute top-[50%] cursor-pointer -translate-y-1/2 rounded-full w-auto h-auto p-0 right-0 z-[2]`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={nextArrowMob} alt="next" />
    </div>
  );
};

export const PrevArrowMob = (props: ArrowProps) => {
  const { style, onClick } = props;
  return (
    <div
      className={`absolute top-[50%] cursor-pointer -translate-y-1/2 rounded-full w-auto h-auto p-0 left-0 z-[2]`}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    >
      <img src={prevArrowMob} alt="prev" />
    </div>
  );
};
