import { useTranslation } from "react-i18next";
import GoldMap from "../../images/MainImages/goldMap.png";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../constants/Regs";
import { useState } from "react";
import axios from "axios";
import { Loader } from "../Loader/Loader";
import { titleAnimation } from "../../animations/animations";
import { motion } from "framer-motion";
import { useNavigate, useSearchParams } from "react-router-dom";

export const ApplicationSection = () => {
  const [isSuccess, setSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pixel = searchParams.get("pixel");

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    Name: Yup.string()
      .min(2, t("formsWarnings.tooShort"))
      .max(50, t("formsWarnings.tooLong"))
      .required(t("formsWarnings.symbols")),
    Phone: Yup.string()
      .matches(phoneRegExp, t("formsWarnings.phoneNumber"))
      .required(t("formsWarnings.enterPhoneNumber")),
    Country: Yup.string().required(t("formsWarnings.selectCountry")),
  });

  async function getUserIP() {
    try {
      const response = await axios.get('https://api.ipify.org?format=json');
      const ipAddress = response.data.ip;
      return ipAddress;
    } catch (error) {
      return null;
    }
  }

  return (
    <div
      className="h-[63.125rem] flex flex-col justify-center md:h-[53.125rem]"
      style={{
        backgroundImage: `url(${GoldMap})`,
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "fixed",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="container__big text-white flex flex-col md:flex-row">
        <motion.h2
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.01, once: true }}
          variants={titleAnimation}
          className="mr-10 w-full md:text-left md:w-2/4"
        >
          {t("requestForm.title")}
        </motion.h2>
        <Formik
          initialValues={{
            Name: "",
            Phone: "",
            Country: "",
            DestinationAddress: "support@workconsultinggroup.me",
            ip: null,
            ua: navigator.userAgent,
            domain: window.location.origin,
          }}
          validationSchema={DisplayingErrorMessagesSchema}
          onSubmit={async (values, { resetForm }) => {
            setInProgress(true);
            setSuccess(false);
            values["ip"] = await getUserIP();
            console.log(values["ua"]);
            console.log(values["ip"]);
            console.log(values["domain"]);
            axios
              .post(
                "https://wdg.biz-crm.ru/inserv/in.php?client=supportworkconsultinggroupme", // URL crm
                values,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((response) => {
                console.log("Success:", response.data);
                setSuccess(true);
                setInProgress(false);
                resetForm();
                window.location.href = "https://workconsultiinggroup.me/success/success.php";
              })
              .catch((error) => {
                console.error("Something went wrong", error);
                setSuccess(false);
                setInProgress(false);
              });
          }}
        >
          {({ errors, touched, setFieldValue }) => (
            <Form className="w-full text-black pt-10 md:pt-0 md:w-2/4">
              <div className="mb-[1.563rem]">
                <div className="relative">
                  <label htmlFor="FullName" className="form-label">
                    {t("requestForm.firstName")}
                    <span className="text-[#eb5757]">*</span>
                  </label>
                  <Field
                    id="Name"
                    name="Name"
                    type="text"
                    className={`form-input shadow-inputShadow ${
                      touched.Name && errors.Name && "!border-[#eb5757]"
                    }`}
                  />
                  {touched.Name && errors.Name && (
                    <span className="form-req-info">{errors.Name}</span>
                  )}
                </div>
              </div>
              <div className="mb-[1.563rem]">
                <div className="relative">
                  <label htmlFor="Phone" className="form-label">
                    {t("requestForm.phone")}
                    <span className="text-[#eb5757]">*</span>
                  </label>
                  <Field
                    id="Phone"
                    name="Phone"
                    type="tel"
                    className={`form-input shadow-inputShadow ${
                      touched.Phone &&
                      errors.Phone &&
                      "!border-[#eb5757]"
                    }`}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setFieldValue("Phone", value);
                    }}
                    maxLength="15"
                  />
                  {touched.Phone && errors.Phone && (
                    <span className="form-req-info">{errors.Phone}</span>
                  )}
                </div>
              </div>
              <div className="mb-[1.563rem]">
                <div className="relative">
                  <label htmlFor="Country" className="form-label">
                    {t("requestForm.country")}
                    <span className="text-[#eb5757]">*</span>
                  </label>
                  <Field
                    as="select"
                    id="Country"
                    name="Country"
                    className={`form-input shadow-inputShadow ${
                      touched.Country && errors.Country && "!border-[#eb5757]"
                    }`}
                  >
                    <option
                      value=""
                      label={t("requestForm.option1")}
                      disabled
                      selected
                      className="text-[#bfbfbf]"
                    />
                    <option
                      value={t("requestForm.option2")}
                      label={t("requestForm.option2")}
                    />
                    <option
                      value={t("requestForm.option3")}
                      label={t("requestForm.option3")}
                    />
                    <option
                      value={t("requestForm.option4")}
                      label={t("requestForm.option4")}
                    />
                    <option
                      value={t("requestForm.option5")}
                      label={t("requestForm.option5")}
                    />
                    <option
                      value={t("requestForm.option6")}
                      label={t("requestForm.option6")}
                    />
                    <option
                      value={t("requestForm.option7")}
                      label={t("requestForm.option7")}
                    />
                    <option
                      value={t("requestForm.option8")}
                      label={t("requestForm.option8")}
                    />
                  </Field>
                  {touched.Country && errors.Country && (
                    <span className="form-req-info">{errors.Country}</span>
                  )}
                </div>
              </div>
              <div className="relative">
                {inProgress ? (
                  <Loader height="51" width="100%" color="#1f23d7" />
                ) : (
                  <button
                    type="submit"
                    className={`top__button !w-[12.5rem] m-auto`}
                  >
                    {isSuccess
                      ? t("requestForm.successfully")
                      : t("requestForm.button")}
                  </button>
                )}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
