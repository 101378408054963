import Slider from "react-slick";
import { ICarousel } from "../../interfaces/CarouselInterface";
import { NextArrow, PrevArrow } from "./Arrows";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  swipe: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
};

export const Carousel = ({ imgComments }: ICarousel) => {
  return (
    <div className="overflow-hidden">
      <Slider
        {...settings}
        className="px-[40px] xsm:px-[65px] max-w-[340px] xxsm:max-w-[435px] ssss:max-w-[530px] max-h-[500px]"
      >
        {imgComments.map((img: string, index: number) => (
          <div className="flex items-center justify-center p-6" key={index}>
            <img src={img} alt="imgComment" className="w-[100%] h-[100%]" />
          </div>
        ))}
      </Slider>
    </div>
  );
};
