import { ThreeCircles } from "react-loader-spinner";
import { ILoader } from "../../interfaces/LoaderInterface";

export const Loader = ({ height, width, styles, color }: ILoader) => {
  return (
    <div className={`container-standard h-full w-full ${styles}`}>
      <ThreeCircles
        height={height}
        width={width}
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor={color}
        innerCircleColor={color}
        middleCircleColor={color}
      />
    </div>
  );
};
