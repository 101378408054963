import Slider from "react-slick";
import { NextArrow, NextArrowMob, PrevArrow, PrevArrowMob } from "./Arrows";
import { PositionItem } from "../PositionnItem/PositionItem";
import MilkaCard from "../../images/MainImages/MilkaCard.png";
import dhlCard from "../../images/MainImages/dhlCard.png";
import hiltonCard from "../../images/MainImages/hiltonCard.png";
import ewmCard from "../../images/MainImages/ewmCard.png";
import boschCard from "../../images/MainImages/boschCard.png";
import desnmCard from "../../images/MainImages/desnmCard.png";
import cleaningCard from "../../images/MainImages/cleaningCard.png";
import aygrovCard from "../../images/MainImages/aygrovCard.png";
import { useTranslation } from "react-i18next";

let width = window.innerWidth;
let slideToShow;
let isSwipe = false;

if (width <= 640) {
  isSwipe = true;
}

if (width <= 930) {
  slideToShow = 1;
}
if (width > 930) {
  slideToShow = 2;
}
if (width > 1300) {
  slideToShow = 3;
}

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: slideToShow,
  slidesToScroll: 1,
  arrows: true,
  swipe: isSwipe ? true : false,
  nextArrow: isSwipe ? <NextArrowMob /> : <NextArrow />,
  prevArrow: isSwipe ? <PrevArrowMob /> : <PrevArrow />,
};

export const VacCarousel = () => {
  const { t } = useTranslation();

  return (
    <div className="overflow-hidden">
      <Slider {...settings} className="px-0 ssss:px-[4.063rem]">
        <PositionItem
          title={t("openPositions.milka.title")}
          img={`${MilkaCard}`}
          salary={t("openPositions.milka.salary")}
          schedule={t("openPositions.milka.schedule")}
          housing={t("openPositions.milka.housing")}
          employment={t("openPositions.milka.employment")}
          city={t("openPositions.milka.city")}
        />
        <PositionItem
          title={t("openPositions.dhl.title")}
          img={`${dhlCard}`}
          salary={t("openPositions.dhl.salary")}
          schedule={t("openPositions.dhl.schedule")}
          housing={t("openPositions.dhl.housing")}
          employment={t("openPositions.dhl.employment")}
          experience={t("openPositions.dhl.experience")}
          city={t("openPositions.dhl.city")}
        />
        <PositionItem
          title={t("openPositions.hilton.title")}
          img={`${hiltonCard}`}
          salary={t("openPositions.hilton.salary")}
          schedule={t("openPositions.hilton.schedule")}
          housing={t("openPositions.hilton.housing")}
          employment={t("openPositions.hilton.employment")}
          city={t("openPositions.hilton.city")}
        />
        <PositionItem
          title={t("openPositions.ewm.title")}
          img={`${ewmCard}`}
          salary={t("openPositions.ewm.salary")}
          schedule={t("openPositions.ewm.schedule")}
          housing={t("openPositions.ewm.housing")}
          employment={t("openPositions.ewm.employment")}
          experience={t("openPositions.ewm.experience")}
          city={t("openPositions.ewm.city")}
        />
        <PositionItem
          title={t("openPositions.bosch.title")}
          img={`${boschCard}`}
          salary={t("openPositions.bosch.salary")}
          schedule={t("openPositions.bosch.schedule")}
          housing={t("openPositions.bosch.housing")}
          employment={t("openPositions.bosch.employment")}
          experience={t("openPositions.bosch.experience")}
          city={t("openPositions.bosch.city")}
        />
        <PositionItem
          title={t("openPositions.desnm.title")}
          img={`${desnmCard}`}
          salary={t("openPositions.desnm.salary")}
          schedule={t("openPositions.desnm.schedule")}
          housing={t("openPositions.desnm.housing")}
          employment={t("openPositions.desnm.employment")}
          experience={t("openPositions.desnm.experience")}
          city={t("openPositions.desnm.city")}
        />
        <PositionItem
          title={t("openPositions.cleaning.title")}
          img={`${cleaningCard}`}
          salary={t("openPositions.cleaning.salary")}
          schedule={t("openPositions.cleaning.schedule")}
          housing={t("openPositions.cleaning.housing")}
          employment={t("openPositions.cleaning.employment")}
          experience={t("openPositions.cleaning.experience")}
          city={t("openPositions.cleaning.city")}
        />
        <PositionItem
          title={t("openPositions.aygrov.title")}
          img={`${aygrovCard}`}
          salary={t("openPositions.aygrov.salary")}
          schedule={t("openPositions.aygrov.schedule")}
          housing={t("openPositions.aygrov.housing")}
          employment={t("openPositions.aygrov.employment")}
          experience={t("openPositions.aygrov.experience")}
          city={t("openPositions.aygrov.city")}
        />
      </Slider>
    </div>
  );
};
